#homeContent {
  padding-top: 3rem;
}
#homeGif {
  object-fit: contain;
  width: 100%;
  align-self: center;
}
#scrollIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  background-color: #0f62fe;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 3rem;
  opacity: 0;
}
#scrollIndicator svg {
  color: white;
}
#gifHomeBlock {
  height: calc(100vh);
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #fefefe;
}
#gifHomeBlock img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
#blackHomeBlockImgContainer {
  height: calc(100% + 1rem);
  width: 100%;
  margin-top: -1rem;
}
#blackHomeBlockImgContainer img {
  height: 100%;
  width: 100%;
  /* object-fit: contain; */
}
#blackHomeBlockContent {
  min-height: 345px;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 0;
  position: relative;
  height: 100%;
  width: 100%;
}
#blackHomeBlockButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
}
#blackHomeBlockText {
  width: 80%;
  font-size: 70px;
  font-weight: 300;
  line-height: 75px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  color: #ffffff;
}
#blackHomeBlockTextMobile {
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  color: #ffffff;
}

.blackHomeBlockColumn {
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 0 !important;
  height: 100%;
}
.homeBlockHeaderTitle {
  font-weight: 500;
  font-size: 28px;
  width: 100%;
}
.homeBlockHeaderDetails {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.homeBlockHeaderTopic {
  font-weight: 300;
  font-size: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
}
.homeBlockHeaderImgContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
}
.homeBlockHeaderImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
}
#carouselPages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 2rem;
}
.carouselPage {
  border-radius: 100%;
  background-color: #c6c6c6;
  height: 8px;
  width: 8px;
}
.carouselPageSelected {
  border-radius: 100%;
  background-color: #0f62fe;
  height: 8px;
  width: 8px;
}
.homeBlockContentPsTitle {
  font-weight: 550;
  font-size: 20px;
  padding-bottom: 2rem;
}
.homeBlockContentPsText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: 400;
  font-size: 16px;
}
.spacer {
  height: 0.5px;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 5rem;
  margin-bottom: 5rem;

  background-color: #262626;
}
.tab {
  height: 4rem;
  width: 10%;
  white-space: wrap !important;
}

.cds--tab--list {
  overflow: hidden !important;
}

.cds--tab--overflow-nav-button {
  display: none !important;
}
.whatWeDoItem {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: center;
}
.whatWeDoTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: left;
}
.whatWeDoText {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
.whatWeDoImgContainer {
  width: 100%;
}
.whatWeDoImgContainer img {
  width: 100%;
  object-fit: contain;
}
#teamGridContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#teamGridText {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
#teamGridMobile {
  display: flex;
  gap: 5%;
  align-items: start;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  position: relative;
}
#teamGrid {
  display: flex;
  gap: 2%;
  align-items: start;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  position: relative;
}
.teamMemberMobile {
  width: 30%;
  height: 16rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.teamMember {
  width: 15%;
  height: 16rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.teamMember img {
  width: 100%;
  object-fit: contain;
}
.teamMemberData {
  height: 100%;
  padding: 10px;
  background-color: #f4f4f4;
}
.teamMemberName {
  font-size: 13px;
  font-weight: bold;
  line-height: 27px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
.teamMemberPosition {
  font-size: 12px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
.homeBlock {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 1;
}
.animateLeft,
.animateRight {
  opacity: 0 !important;
}
@keyframes animationLeft {
  0% {
    opacity: 0;
    margin-left: -100%;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
@keyframes animationRight {
  0% {
    opacity: 0;
    margin-left: 100%;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes moveUpDown {
  0% {
    bottom: 3rem;
  }
  50% {
    bottom: 5rem;
  }
  100% {
    bottom: 3rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .animated-right {
    animation: animationRight 2s 1;
    opacity: 1;
    margin: 0;
  }
  .animated-left {
    animation: animationLeft 2s 1;
    opacity: 1;
    margin: 0;
  }
  .appear {
    animation: appear 5s 1;
  }
  .disappear {
    animation: disappear 1s 1;
  }
  .moveUpDown {
    opacity: 1 !important;
    animation: moveUpDown 3s infinite;
  }
}
