#assetContent {
  padding-top: 3rem;
}

#firstAssetBlock {
  position: relative;
  background-color: #262626;
  min-height: 40vh;
  width: 100%;
}
#breadCrumb {
  position: absolute;
  top: 1rem;
  left: 2rem;
}
#assetGrid .assetColumn {
  margin: 0;
}
.breadcrumbWhiteText .cds--link {
  color: white !important;
}
.breadcrumbWhiteText::after {
  color: white !important;
}
.assetTitle {
  color: white;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
#categoryImgContainer {
  display: flex;
  justify-content: end;
  width: 100%;
  height: 100%;
}
#categoryImgContainer img {
  width: 90%;
  height: 100%;
}

#assetFirstColumnContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 2rem;
}
#assetFirstColumnTitle {
  font-size: 28px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 4rem;
}
#assetFirstColumnInfo {
  width: 100%;
  padding-bottom: 2rem;
}
.assetFirstColumnInfoItem {
  padding-bottom: 2rem;
}
.assetFirstColumnInfoTitle {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-bottom: 1rem;
}
.assetFirstColumnInfoText {
  color: #525252;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3199999928474426px;
  text-align: left;
}
#assetFirstColumnTags {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding-bottom: 1rem;
}
#assetFirstColumnTags :first-child {
  margin-left: 0;
}
.assetDoc {
  font-family: IBM Plex Sans !important;
  padding: 3rem 4rem 4rem 4rem;
}
.assetDocMobile {
  font-family: IBM Plex Sans !important;
  padding: 2rem 1.5rem 3rem 1.5rem;
}
.assetDoc ul p {
  padding-top: 1rem;
}
.assetDoc p,
li {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
.assetDocMobile p,
li {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
#assetVideoPlayer {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  box-shadow: 0px 2px 6px 0px #0000004d;
}
#archTitle {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-bottom: 1.5rem;
  margin-top: 2vh;
}
#assetArch {
  width: 100%;
  height: 100%;
}
