.tabPanelFirstColumn {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
}
.tabPanelImg {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 1rem;
  object-fit: contain;
  width: 100%;
  height: 100%;
  align-self: center;
}
.tabPanelImg img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.tabPanelTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.tabPanelText {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
