#catalogContent {
  padding-top: 3rem;
}
.catalogBlock {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 1;
  justify-content: center;
}
#firstCatalogBlock {
  position: relative;
  background-color: #262626;
  min-height: 70vh;
  width: 100%;
}
#breadCrumb {
  position: absolute;
  top: 1rem;
  left: 2rem;
}
.breadcrumbWhiteText .cds--link {
  color: white !important;
}
.breadcrumbWhiteText::after {
  color: white !important;
}
.firstCatalogTitle {
  color: white;
  font-size: 96px;
  font-weight: 400;
  line-height: 107px;
  text-align: left;
}
.firstCatalogTitleMobile {
  padding-left: 1rem;
  color: white;
  font-size: 45px;
  font-weight: 400;
  text-align: left;
  line-height: 45px;
}
.firstCatalogText {
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  padding-top: 3rem;
}
.firstCatalogTextMobile {
  padding-left: 1rem;
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  padding-top: 3rem;
}
.gradient {
  background: linear-gradient(90deg, #ffffff, #0062ff);
  animation: gradient 10s linear infinite;
  background-size: 400% 400%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#firstCatalogImageContainer {
  height: 100%;
  width: 100%;
  min-height: 200px;
  min-width: 100px;
  position: relative;
}
#firstCatalogImage1 {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}
#firstCatalogImage2 {
  position: absolute;
  bottom: 0;
  right: 0;
}
#catalogGrid .catalogColumn {
  margin: 0;
}
#catalogFirstColumnContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 2rem;
}
#catalogFirstColumnTitle {
  font-size: 28px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 4rem;
}
#catalogFisrtColumnFilters {
  width: 100%;
}
#catalogFisrtColumnFilterTitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-bottom: 1.5rem;
}
#catalogFisrtColumnTags {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding-bottom: 1rem;
}
#catalogFisrtColumnTags :first-child {
  margin-left: 0;
}
#catalogFisrtColumnClear {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-bottom: 1rem;
}
#assetGridContainer {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 3rem;
}
.cds--tabs {
  margin-top: 3rem !important;
}
.cds--tab--list {
  width: 100% !important;
  overflow: hidden;
}

.cds--tabs__nav-item {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.assetEntry {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.assetEntry .admin-controls .icon {
  color: white;
  margin-bottom: 8vh;
}

.assetEntry .admin-controls .icon + .icon{
  margin-left: 1vw;
}
.assetImg {
  width: 100%;
  
}
.assetImg img {
  width: 100%;
  aspect-ratio: 16/10;
}
.assetInfo {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  background-color: #525252;
  min-height: 100px;
  height: 100%;
}

.assetName {
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin-bottom: 1vh;
}

.assetCategories p {
  color: white;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}

.cds--form-item:last-child {
  margin-top: 2vh;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
