#login {
   padding: 20vh 6vw 5vh 6vw;
   height: 70vh;
   margin-bottom: 20vh;
}

#login .content {
    padding-right: 4vw;
}

#login .content .divider {
    display: block;
    height: 1px;
    border: none;
    margin: 2vh 0 6vh 0;
    background: var(--cds-border-subtle);
}

#login .content .cds--btn--primary {
   max-width: 100% !important;
   width: 100%;
   margin-top: 4vh;
}

#login .content .cds--btn--primary .cds--inline-loading {
   top: 0;
   margin-top: 1vh
}

#login-grid {
    padding: 0;
}

#login-grid .login-column {
    margin: 0;
}