@use "@carbon/react";
@use "@carbon/react/scss/zone";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap");

* {
  box-sizing: border-box;
}
.cds--css-grid-column {
  padding-top: 1rem !important;
}

.cds--css-grid {
  max-inline-size: none !important;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

*::-webkit-scrollbar {
  display: none; /* chrome */
}
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}