#notification {
  position: fixed;
  right: 0;
  top: 3rem;
  animation: fade 5s linear;
  z-index: 9999;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
