#techContent {
  padding-top: 3rem;
}
#breadCrumbTech {
  display: flex;
  align-items: center;
  min-height: 4rem;
  width: 100%;
  position: sticky;
  top: 3rem;
  left: 0rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  border-bottom: 1px solid #a8a8a8;
  background-color: #f4f4f4;
}
#breadCrumbTechMobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: #161616;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
}
#techGrid .techColumn {
  margin: 0;
  padding-top: 0 !important;
}

.breadcrumbBlackText .cds--link {
  color: #161616 !important;
}
.breadcrumbBlackText::after {
  color: #161616 !important;
}
.techTitle {
  color: white;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
#categoryImgContainer {
  display: flex;
  justify-content: end;
  width: 100%;
  height: 100%;
}
#categoryImgContainer img {
  width: 90%;
  height: 100%;
}
#techFirstColumnContent {
  height: calc(100vh - 3rem);
  overflow: scroll;
  position: sticky;
  top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  color: white;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
#techFirstColumnContentMobile {
  height: calc(60vh);
  overflow: scroll;
  position: sticky;
  top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  color: white;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

#techFirstColumnContent button, #techFirstColumnContentMobile button {
  margin-left: 2rem;
  margin-top: 2vh;
}

#techFirstColumnTitleContainer {
  padding-left: 2rem;
  width: 85%;
}
#techFirstColumnLine {
  background-color: white;
  height: 1px;
}
#techFirstColumnText {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-top: 1rem;
}
#techFirstColumnTitle {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.techFirstColumnTopic {
  cursor: pointer;
  margin-top: 0.5rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.techFirstColumnTopicTitle {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
.techFirstColumnChild {
  padding-left: 1rem;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
.selected {
  border-left: 4px solid #0362ff;
  padding-left: calc(2rem - 4px) !important;
  background-color: #393939;
}
.bold {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 16px !important;
  font-weight: bold !important;
}
#techFirstColumnInfo {
  width: 100%;
  padding-bottom: 2rem;
}
.techFirstColumnInfoItem {
  padding-bottom: 2rem;
}
.techFirstColumnInfoTitle {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  padding-bottom: 1rem;
}
.techFirstColumnInfoText {
  color: #525252;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3199999928474426px;
  text-align: left;
}
#techFirstColumnTags {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  width: 100%;
  padding-bottom: 1rem;
}
#techFirstColumnTags :first-child {
  margin-left: 0;
}
.techDoc {
  font-family: IBM Plex Sans !important;
  padding: 3rem 4rem 4rem 4rem;
}

.techDocMobile {
  font-family: IBM Plex Sans !important;
  padding: 2rem 1.5rem 3rem 1.5rem;
}
.techDoc p,
.techDocMobile p {
  padding-top: 1rem;
}
.techDoc img,
.techDocMobile img {
  width: 100%;
  padding-bottom: 1rem;
}
.techDoc pre,
.techDocMobile pre {
  width: 100%;
  padding-top: 1rem;
}
.techDoc h2,
.techDocMobile h2 {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.techDoc h3,
.techDocMobile h3 {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.techDoc p,
.techDoc li,
.techDoc ul,
.techDoc ol {
  list-style: unset !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
  letter-spacing: 0.1599999964237213px !important;
  text-align: left !important;
}
.techDoc ul,
.techDoc ol {
  padding-left: 3rem !important;
}
.techDocMobile ul,
.techDocMobile ol {
  padding-left: 3rem !important;
}
.techDocMobile p,
.techDocMobile li,
.techDocMobile ul,
.techDocMobile ol {
  list-style: unset !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
}
.techDoc code,
.techDocMobile code {
  display: inline-block;
  max-width: 100%;
  background-color: rgb(38, 38, 38);
  color: rgb(244, 244, 244);
  border-radius: 5px;
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-wrap: wrap;
}

.techDoc *,
.techDocMobile * {
  scroll-margin-top: 6rem;
}
