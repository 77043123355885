#footer {
    height: 311px;
    background-color: #262626;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

#footer img {
    width: 195px;
    height: 130px;
}

#mobileFooter {
    height: 311px;
    background-color: #262626;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content:space-between;
    padding-top: 1rem;
    padding-left: 0rem;
}

#mobileFooter img {
    height: 77px;
    width: 115px;
}

#language-select {
    width: 30%
}