#content_groups {
    display: flex;
    margin-top: 5vh;
    height: 90vh;
    width: 100%;
}

.loadingInfo {
    justify-content: center;
}

#main_groups {
    border-right: 2px solid #E0E0E0;
    width: 25%;
    padding: 2rem;
}

#main_groups h3 {
    font-size: 1.4rem;
    font-family: 'IBM Plex Sans', sans-serif;
    border-bottom: 2px solid #E0E0E0;
    margin-bottom: 20px;
    padding-bottom: 18px;
}

.cds--list__item {
    font-size: 14px;
    font-weight: 400;
    list-style-type: none;
    margin-bottom: 7%;
 
}
.cds--list__item a {
color: black !important;
}

.cds--list__item:hover, .cds--link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.bx--fieldset {
    max-height: 80vh;
    overflow-y: scroll;
}

#main_FormG {
    width: 100%;
    padding: 3rem;
}

.bx--tooltip__label {
    font-size: 1rem;
    font-weight: 500;
    color: #525252;
}

#add-new-user {
    margin-top: 2vh;
}

#danger-btn {
    margin-right: 0.05vw;
}

#new_user_modal {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#new_user_modal .bx--inline-loading {
    max-width: fit-content;
    margin-left: 1vw;
}

#form {
    padding:0 2vw;
    width: 75%;
}