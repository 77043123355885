.accordionFirstColumn {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  height: 420px;
}
.accordionImg {
  display: flex;
  flex-direction: column;
  align-items: start;
  object-fit: contain;
  width: 100%;
  height: 100%;
  align-self: center;
}
.accordionImg img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.accordionTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
}
.accordionText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
